<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Attribute</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main   px-3 "
              @click="goto()"
            >
              Create
            </button>
          </div>
        </div>
        <!-- ------------------------ -->

        <div class="row m-0 px-3">
          <div class="col-12 col-xl-6">
            <div class="form-group">
              <label>Attribute Group</label>
              <!-- <select
                class="form-control form-control-sm"
                v-model="data.attrGroupId"
              >
                <option value="">All</option>
                <option
                  :value="e.attrGroupId"
                  v-for="(e, i) in option_attrGroupId"
                  :key="i"
                  >{{ e.labelTH + ` / ` + e.labelEN +` (${e.catCode})`}}</option
                >
              </select> -->

              <cool-select
                v-model="data.attrGroupId"
                :items="option_attrGroupId"
                :placeholder="data.attrGroupId ? '' : 'All'"
                item-value="attrGroupId"
                item-text="labelName"
                class="IZ-select-sm"
              >
                <!-- slot for each item in the menu -->
                <template slot="item" slot-scope="{ item: e }">
                  <div style="display: flex; align-items: center;">
                    <div>
                      {{ e.labelName }}
                    </div>
                  </div>
                </template>
                <!-- slot for the selected item (in the text field) -->
                <template slot="selection" slot-scope="{ item: e }">
                  <div>
                    {{ e.labelName }}
                  </div>
                </template>
              </cool-select>
            </div>
          </div>
          <!-- --------------------- -->
          <div class="col-12 col-xl-6">
            <div class="form-group">
              <label>Search</label>
              <div class="input-group input-group-sm   ">
                <div class="input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="mode">
                    <option
                      v-for="(e, i) in select_mode"
                      :key="i"
                      :value="e.key"
                    >
                      {{ e.name }}
                    </option>
                  </select>
                </div>
                <input
                  type="search"
                  class="form-control form-control-sm"
                  placeholder="ค้นหา / Seach"
                  v-model="find"
                  v-on:keyup.enter="Search()"
                />
                <div class="input-group-append">
                  <button class="btn btn-sm bt-main" @click="Search()">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 px-3">
          <div class="col-12">
            <p class="px-3 font-600 w-100" style="display: flex">
              สินค้า&nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
              <span style="flex: 1; text-align: right; white-space: nowrap;"
                ><button class="btn btn-sm bt-bnb mr-2" @click="exportExcel()">
                  Export Data <span v-html="$svg_icon.excel"></span>
                </button>
                <button
                  type="button"
                  class="btn bt-main btn-sm mx-1 px-4"
                  @click="$refs.file.click()"
                >
                  <span class="mx-1" v-html="$svg_icon.upload"></span>Import
                  Data
                </button>
                <input
                  type="file"
                  multiple
                  @change="UploadFile($event)"
                  ref="file"
                  style="display: none"
                />
              </span>
            </p>
          </div>

          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              outlined
              hover
              show-empty
              responsive
              class="font-0-8s"
            >
              <template #cell(Group)="row">
                {{ row.item.labelTH_Group + ` / ` + row.item.labelEN_Group }}
              </template>
              <template #cell(active)="row">
                <span
                  :class="[row.item.active == 1 ? 'badge-main' : 'badge-twd']"
                  class="badge"
                  >{{ row.item.active == 0 ? "No" : "Yes" }}</span
                >
              </template>
              <template #cell(Action)="row">
                <span
                  type="button"
                  class="text-muted mx-2"
                  v-html="$svg_icon.edit"
                  @click="edit(row)"
                ></span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div
                class="col-6 col-xl-1 input-group-prepend input-group-sm  mb-3"
              >
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3  mb-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileDownload from "js-file-download";
import { CoolSelect } from "vue-cool-select";
export default {
  name: "Attributes",
  components: {
    CoolSelect,
  },
  data() {
    return {
      reModalcatcode: Date.now() + 5,
      data: {
        attrGroupId: "",
      },
      option_attrGroupId: [],
      totalRows: 0,
      items: [],
      mode: "attrlabelTH",
      find: "",
      select_mode: [
        { name: "ค้นหาด้วย labelTH", key: "attrlabelTH" },
        { name: "ค้นหาด้วย labelEN", key: "attrlabelEN" },
      ],
      itemlist: [],

      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "No", label: "No", class: "text-center" },
        { key: "attrname", label: "attributeName" },
        { key: "attrlabelTH", label: "labelTH" },
        { key: "attrlabelEN", label: "labelEN" },
        { key: "filterName", label: "filterType", class: "text-center" },
        { key: "attrDescTH", label: "attrDescTH" },
        { key: "attrDescEN", label: "attrDescEN" },
        { key: "active", label: "Active", class: "text-center" },

        // { key: "catCode", label: "catCode" },
        // { key: "SubDept", label: "SubDept" },

        {
          key: "Action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    "data.attrGroupId": function(v) {
      if (!!!v) {
        this.data.attrGroupId = "";
      }
      this.getdata_list();
      this.find = "";
    },
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {
    this.get_option_attrGroupId();
    this.getdata_list();
  },
  methods: {
    exportExcel() {
      let json = this.itemlist;
      let name = `AttributeData.xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
    },
    async UploadFile(e) {
      try {
        let readfile = await this.$serviceMain.ReadExcel(e.target.files[0]);
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการอัปโหลดข้อมูล Attribute จำนวน ${readfile.length} รายการ หรือไม่? / Do you want to update data?`
        );
        let data = { jsnAttribute: readfile };
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/uploadPimAttributeDesc`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
        this.getdata_list();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async get_option_attrGroupId() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_groups_list`,
          {},
          1
        );

        this.option_attrGroupId = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdata_list() {
      try {
        let { attrGroupId } = this.data;
        let data = { attrGroupId: attrGroupId, attrId: "" };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute`,
          data,
          1
        );
        //  console.log(getAPI.data);
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = this.items.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    edit(data) {
      let { attrId } = data.item;

      this.$router.push({
        name: "Attributes-Edit",
        query: { attr: attrId },
      });
    },
    goto() {
      this.$router.push({ name: "Attributes-Create" });
    },

    callModalcatcode() {
      this.reModalcatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalcatcode");
      }, 100);
    },
    return_data(data) {
      let { catcode, selectnow } = data;
      this.data.cattext = selectnow;
      this.data.catCode = catcode;
    },
    Clearcatcode() {
      this.data.cattext = "";
      this.data.catCode = "";
    },
  },
};
</script>

<style></style>
